<template>
  <div>
    <i2-form-input
      v-if="isInputText()"
      v-model="field.value"
      :label="field.name"
      :name="field.uuid"
      :type="field.type === 'number' ? 'number' : 'text'"
      @input="change()"
    />
    <i2-form-input-date
      v-if="field.type === 'date'"
      v-model="field.value"
      :name="field.uuid"
      :label="field.name"
      @input="change()"
    />
  </div>
</template>

<script>
export default {
  name: 'DynamicField',
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dynamicField: {},
      inputValue: '',
      typeRequired: 'text',
    }
  },
  mounted() {
    this.dynamicField = this.field
  },
  methods: {
    isInputText() {
      return this.field.type === 'text' || this.field.type === 'number'
    },
    change() {
      this.dynamicField.value = this.field.value
      this.$emit('onInput', this.dynamicField)
    },
  },
}
</script>

<style scoped>

</style>
