<template>
  <b-modal
    id="form-sidebar"
    size="lg"
    :visible="isActive"
    backdrop
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    bg-variant="white"
    title="Cadastro de Estação"
    right
    shadow
    :hide-footer="true"
    @hidden="onCancel"
    @hide="onCancel"
    @change="(val) => $emit('update:is-active', val)"
  >
    <b-card>
      <b-tabs>
        <b-tab @click="setCurrentTab('data')">
          <template #title>
            <feather-icon icon="FileTextIcon" />
            Dados
          </template>

          <template #default="{ hide }">
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >
              <b-form
                class="p-2"
                @submit.prevent="handleSubmit(onSubmit)"
              >
                <b-row>
                  <b-col
                    sm="12"
                    md="12"
                    class="text-right"
                  >
                    <b-form-checkbox
                      v-model="model.status"
                      :checked="model.status === 1"
                      class="custom-control-success"
                      name="station_status"
                      switch
                      inline
                    >
                      Descartado
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    sm="12"
                    md="12"
                  >
                    <i2-form-tags :value="model.tags" context="stations" @onChange="changeTag" />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    sm="12"
                    md="12"
                  >
                    <i2-form-vue-select
                      v-model="model.location_uuid"
                      :options="locations"
                      rules="required"
                      label="Local do Ativo"
                      name="station_location"
                    />

                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    sm="12"
                    md="12"
                  >
                    <i2-form-input
                      v-model="model.name"
                      rules="required"
                      label="Nome"
                      placeholder=""
                      name="station_name"
                    />
                  </b-col>
                </b-row>
                <!-- dynamic fields -->
                <b-row>
                  <b-col
                    v-for="dynamicField of dynamicFields"
                    :key="dynamicField.uuid"
                    sm="4"
                    md="4"
                  >
                    <dynamic-field :field="dynamicField" @onInput="changeDynamicField" />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    sm="12"
                    md="12"
                    class="text-right"
                  >
                    <b-button
                      variant="outline-secondary"
                      type="button"
                      class="mr-1"
                      @click="onCancel"
                    >

                      <font-awesome-icon
                        prefix="far"
                        :icon="['far', 'times-circle']"
                      />
                      Cancelar
                    </b-button>
                    <b-button
                      variant="primary"
                      type="button"
                      @click="onSubmit"
                    >

                      <feather-icon
                        icon="SaveIcon"
                        class=""
                      />
                      Salvar
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </template>
        </b-tab>
        <b-tab :disabled="!model.uuid" @click="setCurrentTab('guarantee')">
          <template #title>
            <feather-icon icon="CheckSquareIcon" />
            Garantia
          </template>
          <StationGuaranteeForm :station-uuid="model.uuid" @onCancel="onCancel" />
        </b-tab>
        <b-tab :disabled="!model.uuid" @click="setCurrentTab('files')">
          <template #title>
            <feather-icon icon="FileIcon" />
            Arquivos
          </template>
          <station-file-list :station-uuid="model.uuid" @onCancel="onCancel" />
        </b-tab>
      </b-tabs>
    </b-card>
  </b-modal>
</template>

<script>
import { alphaNum, required } from '@validations'
import { ValidationObserver } from 'vee-validate'
import StationGuaranteeForm from '@/views/station/StationGuaranteeForm.vue'
import DynamicField from '@/components/studio/dynamic-fields/DynamicField.vue'
import StationFileList from '@/views/stationfile/StationFileList.vue'
import I2FormTags from '@/components/i2/forms/elements/I2FormTags.vue'

export default {
  name: 'StationFormModal',
  directives: {
  },
  components: {
    I2FormTags,
    StationFileList,
    DynamicField,
    StationGuaranteeForm,
    ValidationObserver,
  },
  props: {
    uuid: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      currentTab: 'data',
      locations: [],
      dynamicFields: [],
      model: {
        uuid: '',
        location_uuid: '',
        status: '',
        name: '',
        observation: '',
        tags: [],
      },
    }
  },
  watch: {
    uuid(uuid) {
      if (uuid) {
        this.getData(uuid)
      }
    },
    isActive(value) {
      if (value) {
        this.getLocation()
      }
    },
  },
  mounted() {
    this.getDynamicFields()
  },
  methods: {
    async getLocation() {
      const response = await this.$http.get('locations')
      const data = []
      // eslint-disable-next-line guard-for-in
      for (const index in response) {
        data[index] = { value: response[index].uuid, text: response[index].name }
      }
      this.locations = data
    },
    onSubmit() {
      this.$refs.refFormObserver.validate()
        .then(async success => {
          if (success) {
            let response = {}
            const data = {
              uuid: this.model.uuid,
              location_uuid: this.model.location_uuid.value,
              status: this.model.status === true ? 1 : 0,
              name: this.model.name,
              dynamic_fields: this.dynamicFields,
              tags: this.model.tags,
            }
            if (this.model.uuid) {
              response = await this.$http.put(`stations/${this.model.uuid}`, data)
            } else {
              response = await this.$http.post('stations', data)
            }
            this.model = response
            if (response.location) {
              this.model.location_uuid = { text: response.location.name, value: response.location.uuid }
            }
            if (response.error) {
              this.$notify.error(response.error_message)
              return
            }
            this.$notify.success('Estação salva com sucesso.')
          }
        })
    },
    async getData(uuid) {
      const response = await this.$http.get(`stations/${uuid}`)

      let locationData = ''
      if (response.location) {
        locationData = { text: response.location.name, value: response.location.uuid }
      }

      this.model = {
        uuid: response.uuid,
        location_uuid: locationData,
        status: response.status,
        name: response.name,
        observation: response.observation,
        tags: response.tags ?? [],
      }
      if (response.dynamic_fields) {
        this.dynamicFields.map(dynamicFieldAvailable => response.dynamic_fields.map(dynamicFieldStation => {
          if (dynamicFieldAvailable.uuid === dynamicFieldStation.uuid) {
            dynamicFieldAvailable.value = dynamicFieldStation.value
          }
        }))
      }
    },
    onCancel() {
      this.resetModel()
      // this.$refs.refFormObserver.reset()
      this.$emit('onCancel', true)
      this.getDynamicFields()
    },
    resetModel() {
      this.model = {
        uuid: '',
        location_uuid: '',
        status: '',
        name: '',
        observation: '',
        tags: [],
      }
    },
    setCurrentTab(tab) {
      this.currentTab = tab
    },
    async getDynamicFields() {
      this.dynamicFields = await this.$http.get('dynamic-fields?context=stations')
    },
    changeDynamicField(dynamicField) {
      this.dynamicFields.map(df => {
        if (df.uuid === dynamicField.uuid) {
          return dynamicField
        }
        return df
      })
    },
    changeTag(tags) {
      this.model.tags = tags
    },
  },
}
</script>

<style scoped>

</style>
