<template>
  <b-card>
    <b-row>
      <b-col
        sm="4"
        md="4"
      >
        <i2-form-input-date
          v-model="model.purchase_date"
          label="Data da compra"
          placeholder=""
          name="purchase_date"
        />
      </b-col>
      <b-col
        sm="4"
        md="4"
      >
        <i2-form-input-date
          v-model="model.warranty_date"
          label="Garantia"
          placeholder=""
          name="warranty_date"
        />
      </b-col>

      <b-col
        sm="4"
        md="4"
      >
        <i2-form-input
          v-model="model.invoice"
          label="Nota Fiscal"
          placeholder=""
          name="invoice"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        sm="4"
        md="4"
      >
        <i2-form-input
          v-model="model.warranty_level"
          label="Nível da Garantia"
          placeholder=""
          name="warranty_level"
        />
      </b-col>
      <b-col
        sm="4"
        md="4"
      >
        <i2-form-input-date
          v-model="model.alert_date"
          label="Data de Alerta"
          placeholder=""
          name="alert_date"
        />
      </b-col>

      <b-col
        sm="4"
        md="4"
      >
        <i2-form-input
          v-model="model.patrimony"
          label="Patrimonio"
          placeholder=""
          name="patrimony"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        sm="12"
        md="12"
      >
        <i2-form-textarea
          v-model="model.observation"
          label="Observação"
          placeholder=""
          name="observation"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        sm="12"
        md="12"
        class="text-right"
      >

        <!-- Form Actions -->
        <b-button
          variant="primary"
          type="button"
          class="mr-2"
          @click="onSubmit"
        >

          <feather-icon
            icon="SaveIcon"
            class="mr-50"
          />
          Salvar
        </b-button>
        <b-button
          variant="outline-danger"
          type="button"
          @click="onCancel"
        >

          <font-awesome-icon
            prefix="far"
            :icon="['far', 'times-circle']"
            class="mr-50"
          />
          Cancelar
        </b-button>
      </b-col>
    </b-row>

  </b-card>

</template>

<script>

import moment from 'moment-timezone'

export default {
  name: 'StationGuaranteeForm',
  components: { },
  props: {
    stationUuid: {
      required: true,
      type: String,
      default: '',
    },
  },
  data() {
    return {
      model: {
        uuid: null,
        warranty_date: '',
        purchase_date: '',
        alert_date: '',
        warranty_level: '',
        patrimony: '',
        invoice: '',
        observation: '',
      },
      currentModel: '',
    }
  },
  watch: {
    stationUuid() {
      if (this.$props.stationUuid) {
        this.getData()
      }
    },
  },
  methods: {
    setCurrentModel(model) {
      this.currentModel = model.uuid
    },
    async onSubmit() {
      let response = {}
      const data = {
        warranty_date: this.model.warranty_date,
        purchase_date: this.model.purchase_date,
        alert_date: this.model.alert_date,
        warranty_level: this.model.warranty_level,
        patrimony: this.model.patrimony,
        invoice: this.model.invoice,
        observation: this.model.observation,
        station_uuid: this.$props.stationUuid,
      }

      data.warranty_date = moment(data.warranty_date, 'DD/MM/YYYY').format('YYYY-MM-DD')
      data.purchase_date = moment(data.purchase_date, 'DD/MM/YYYY').format('YYYY-MM-DD')
      data.alert_date = moment(data.alert_date, 'DD/MM/YYYY').format('YYYY-MM-DD')
      if (this.model.uuid) {
        response = await this.$http.put(`guarantees/${this.model.uuid}`, data)
      } else {
        response = await this.$http.post('guarantees', data)
      }

      if (response.error) {
        this.$notify.error(response.error_message)
        return
      }
      this.$notify.success('Garantia salva com sucesso.')
      this.$emit('onSave', true)
    },
    resetModel() {
      this.model = {
        uuid: null,
        warranty_date: '',
        purchase_date: '',
        alert_date: '',
        warranty_level: '',
        patrimony: '',
        invoice: '',
        observation: '',
      }
    },
    onCancel() {
      this.currentModel = ''
      this.$emit('onCancel', true)
    },
    async getData() {
      this.model = await this.$http.get(`stations/${this.$props.stationUuid}/guarantee`)
    },
  },
}
</script>

<style scoped>

</style>
