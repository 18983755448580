<template>
  <b-modal
    v-model="isOpen"
    :title="title"
    no-close-on-esc
    hide-header-close
    ok-title="Importar"
    cancel-title="Fechar"
    @cancel="close"
    @ok="importFile"
  >
    <i2-form-input-file
      v-model="model.file"
      name="file"
      :multiple="false"
      label="Arquivo"
      :accept="accept"
      class="mb-0 pb-0"
    />
    <helper-download v-if="helperDownloadFile !== ''" :href="helperDownloadFile" />
  </b-modal>
</template>

<script>

import { BModal } from 'bootstrap-vue'
import I2FormInputFile from '@/components/i2/forms/elements/I2FormInputFile.vue'
import HelperDownload from '@/components/app/HelperDownload.vue'

export default {
  name: 'ImportFileModal',
  components: {
    HelperDownload,
    I2FormInputFile,
    BModal,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    saveRoute: {
      type: String,
      required: true,
    },
    helperDownloadFile: {
      type: String,
      required: false,
      default: '',
    },
    accept: {
      type: String,
      default: '.csv',
    },
  },
  data() {
    return {
      model: {
        file: null,
      },
      isOpen: false,
      helper: '',
    }
  },
  watch: {
    open(value) {
      if (value) {
        this.isOpen = true
      }
    },
  },
  methods: {
    close() {
      this.isOpen = false
      this.$emit('close', true)
    },
    async importFile(bvModal) {
      bvModal.preventDefault()
      if (!this.model.file) {
        this.$notify.error('Selecione um arquivo do tipo CSV.')
        return
      }
      console.log('arquivo -> ', this.model.file)
      const formData = new FormData()
      formData.append('file', this.model.file)
      const response = await this.$http.post(this.$props.saveRoute, formData)
      this.$notify.success(response)
      this.close()
    },
  },
}
</script>

<style scoped>

</style>
